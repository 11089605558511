import React from "react";
import { Element } from "react-scroll";
import Works from "../sections/home/Works";
import PageWrapper from "../components/PageWrapper";


const ProjectsPage = () => {
    return (
        <>
            <PageWrapper darkTheme>
                <Element name="works">
                    <Works />
                </Element>
            </PageWrapper>
        </>
    );
};

export default ProjectsPage;
